/*** this is only for test!!! ***/
// export const ApiUrl = "http://37.252.70.75:4322/"

export const ApiUrl =
    process.env.NODE_ENV === "development"
        ? "http://192.168.88.200:8000"
        : "https://erp-api.usaautoexport.am"

console.log(process.env.NODE_ENV)

function parseJwt(token) {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(""),
    )

    return JSON.parse(jsonPayload)
}

export const getMetadata = () => {
    localStorage.setItem(
        "lastApiCall",
        parseInt((new Date().getTime() / 1000).toFixed(0)),
    )
    const token = localStorage.getItem("accessToken")
    if (!token) {
        return null
    }
    const jwt = parseJwt(token)
    const current_time = Date.now() / 1000
    if (jwt.exp < current_time) {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("userData")
        window.location.reload()
    }
    return {authorization: "Bearer " + token}
}
